<template>
    <div class="mx-auto">
        <v-dialog 
            v-if="canEdit" 
            v-model="modal"
            :width="dialogWidth">
             <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                    <v-btn 
                        :height="height + (label != null ? 15 : 0)"
                        :width="width"
                        class="mx-auto"
                        v-bind="attrs"
                        v-on="on">
                        <v-container>
                            <div v-if="label" class="my-0 py-0">{{ label }}</div>
                            <v-img :src="src" :height="height" :width="width">
                                <template v-slot:placeholder>
                                    <v-container>
                                        <v-row justify-center align-center>
                                            <v-col class="text-center" cols="12">
                                                <v-icon :size="size - 15">{{ placeholder }}</v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="text-center" cols="12">
                                                <p>Upload Image</p>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </template>
                            </v-img>
                            <v-fade-transition>
                                <v-overlay v-if="hover" absolute :opacity="0.75">
                                    <div><v-icon left>mdi-pencil</v-icon>Edit Image</div>
                                </v-overlay>
                            </v-fade-transition>
                        </v-container>
                    </v-btn>  
                </v-hover>
            </template>
            <v-card 
                :loading="isLoading"
                :width="dialogWidth">
                <v-card-title>Image Editor</v-card-title>
                <v-card-text>
                    <v-image-input 
                        imageBackgroundColor="white"
                        clearable
                        :imageHeight="imageHeight"
                        :imageWidth="imageWidth"
                        imageMinScaling="contain"
                        v-model="imageData"
                        @file-info="onFileInfo"/>
                </v-card-text>
                <v-card-actions>
                    <v-container>
                        <v-row>                               
                            <v-btn class="ml-auto primary" @click="saveImage"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>            
        </v-dialog>
        <v-container v-else class="mx-auto">
            <v-row>
                <v-col>                    
                    <v-img :src="src" :height="height" :width="width">
                        <template v-slot:placeholder>                                      
                            <v-icon :size="size">{{ placeholder }}</v-icon>                                             
                        </template>
                    </v-img>   
                </v-col>
            </v-row>
        </v-container> 
    </div>
</template>

<script>
export default {
    name: 'BT-Image-Edit',
    components: {
        VImageInput: () => import('vuetify-image-input/a-la-carte')        
    },
    data: function() {
        return {
            modal: false,
            imageData: '',
            // showEdit: false,
            d: null,
            isLoading: false,
        }
    },
    props: {
        navigation: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        imageHeight: {
            type: Number,
            default: 256
        },
        imageWidth: {
            type: Number,
            default: 256
        },
        proxyID: {
            type: String,
            default: null
        },
        src: {
            type: String,
            default: null
        },        
        width: {
            type: Number,
            default: 100
        },
        dialogWidth: {
            type: Number,
            default: 500
        },
        height: {
            type: Number,
            default: 100
        },
        placeholder: {
            type: String,
            default: 'mdi-cube-outline'
        },
        label: {
            type: String,
            default: null
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: '100'
        }
    },
    methods: {
        onFileInfo(data) {
            this.d = data;            
        },
        saveImage() {
            this.isLoading = true;
            
            var self = this;
            this.$BlitzIt.api.uploadImage(
                this.navigation,
                this.imageData,
                this.id,
                this.proxyID)
                .then(() => {
                    this.isLoading = false;
                    //this.showEdit = false;
                    this.modal = false;
                    self.$emit('uploaded')
                })
                .catch(err => {
                    console.log(err);
                    this.isLoading = false;
                    this.modal = false;
                    //this.showEdit = false;
                })
        }
    }
}
</script>